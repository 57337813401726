import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "components/Layout";

const NotFoundPage = () => {
  return (
    <Layout notFound={true}>
      <Helmet>
        <title>Losali Direct</title>
        <meta charSet="utf-8" />
      </Helmet>
      <main className="container page-width top-margin">
        <div className="aide card-primary">
          <form>
            <h1 className="main-card-h1">404</h1>
            <br />
            <h1 className="main-card-h1">Sorry, page not found.</h1>
          </form>
        </div>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
